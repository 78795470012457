const inputType = (key) => {
  const selectInput = ["unity", "rank", "position", "dimension"];
  const numberInput = ["tinggi", "berat"];
  const arrayInput = ["honors"];

  if (selectInput.includes(key)) {
    return "select";
  }
  if (numberInput.includes(key)) {
    return "number";
  }
  if (arrayInput.includes(key)) {
    return "array";
  }

  return "text";
};

const parseLabel = (key) => {
  const labels = {
    nip_nrp: "NIP/NRP",
    nama_lengkap: "Nama Lengkap",
    tinggi: "Tinggi",
    berat: "Berat",
    rambut: "Rambut",
    mata: "Mata",
    gol_darah: "Golongan Darat",
    tempat_lahir: "Tempat Lahir",
    tanggal_lahir: "Tanggal Lahir",
    agama: "Agama",
    alamat: "Alamat",
    kontak_darurat: "Kontak Darurat",
    nama_pasangan: "Nama Pasangan",
    nama_ortu: "Nama Orang Tua",
    tempat_kawin: "Tempat Kawin",
    no_surat_kawin: "No Surat Kawin",
    status: "UPLOADED",
    unity: "Kesatuan",
    rank: "Pangkat",
    position: "Jabatan",
    dimension: "Matra",
    honors: "Kehormatan",
  };
  return labels[key] ? labels[key] : key;
};

const hiddenInput = [
  "unityId",
  "rankId",
  "positionId",
  "dimensionId",
  "createdAt",
  "updatedAt",
  "deletedAt",
  "parent_id",
  "file_foto",
  "card_applications",
  "attachments",
  "person",
  "status",
  "id",
  // "honors",
  "period_id",
];

const biodataSection = [
  "nip_nrp",
  "nama_lengkap",
  "unity",
  "rank",
  "position",
  "dimension",
];

const sinyalementSection = [
  "tinggi",
  "berat",
  "rambut",
  "mata",
  "gol_darah",
  "tempat_lahir",
  "tanggal_lahir",
  "agama",
  "kontak_darurat",
  "alamat",
];

const biodataFamilySection = [
  "nama_lengkap",
  "tempat_lahir",
  "tanggal_lahir",
  "agama",
  "nama_ortu",
  "alamat",
];

const sinyalementFamilySection = [
  "tinggi",
  "berat",
  "rambut",
  "mata",
  "gol_darah",
  "tempat_kawin",
  "no_surat_kawin",
];

export {
  inputType,
  parseLabel,
  hiddenInput,
  biodataSection,
  sinyalementSection,
  biodataFamilySection,
  sinyalementFamilySection,
};
