<template>
  <div>
    <div v-for="(value, key) in currentData" :key="key" class="mb-5">
      <div v-if="filtered.includes(key)">
        <label for="">{{ value.label }}</label>
        <div v-if="value.type == 'select'">
          <Field
            :name="key"
            as="select"
            class="form-control"
            v-model="currentData[key].value"
            :disabled="value.disable || readonly"
          >
            <option value="">{{ `Pilih ${value.label}` }}</option>
            <option v-for="(v, k) in value.options" :key="k" :value="v.value">
              {{ v.label }}
            </option>
          </Field>
        </div>
        <!-- <div v-else-if="value.type == 'array'">
          <div v-for="(v, k) in value.before" :key="k">
            <Field
              :name="key"
              type="text"
              class="form-control mt-3"
              v-model="currentData[key].value"
              :disabled="value.disable || readonly"
            />
          </div>
        </div> -->
        <div v-else>
          <Field
            :name="key"
            :type="value.type"
            class="form-control"
            v-model="currentData[key].value"
            :disabled="value.disable || readonly"
          />
        </div>
        <ErrorMessage :name="key" class="text-danger error-feedback" />
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import { Field, ErrorMessage } from "vee-validate";

export default {
  components: { Field, ErrorMessage },
  props: {
    initialForm: {
      type: Object,
    },
    filtered: {
      type: Array,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentData: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.currentData = this.initialForm;
    },
    getUpdatedValue() {
      return Object.keys(this.currentData)
        .filter((key) => this.filtered.includes(key))
        .reduce((obj, key) => {
          obj[key] = toRaw(this.currentData[key].value);
          return obj;
        }, {});
    },
  },
};
</script>
